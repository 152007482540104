import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initialize, selectStatus } from '../../features/firebase';
import Loading from '../Loading';

function WaitForInitialization({ showLoading, children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);
  const status = useSelector(selectStatus);
  if (status !== 'initialized') {
    return showLoading ? <Loading /> : null;
  }
  return children;
}

WaitForInitialization.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  showLoading: PropTypes.bool,
};

WaitForInitialization.defaultProps = {
  showLoading: false,
};

export default WaitForInitialization;
