import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initialize, selectStatus, selectUser } from '../../features/firebase';

function RequireLogin({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  const firebaseUser = useSelector(selectUser);
  const firebaseStatus = useSelector(selectStatus);
  useEffect(() => {
    if (firebaseStatus === 'initialized' && !firebaseUser?.email) {
      window.location.href = `/account/sign-in?next=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    }
  }, [firebaseStatus, firebaseUser]);

  return children;
}

RequireLogin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RequireLogin;
