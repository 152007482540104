import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';

const initialState = {
  userPartIDs: [],
  listeningPartIDs: {},
  parts: {},
  quotes: {},
  status: 'NOT_LISTENING',
  initialized: false,
  error: null,
};

export const partsSlice = createSlice({
  name: 'parts',
  initialState,
  reducers: {
    getPart: (state) => {
      state.status = 'LISTENING';
    },
    getUserParts: (state) => {
      state.status = 'LISTENING';
    },
    setPart: (state, action) => {
      state.parts[action.payload.id] = action.payload;
      // hydrate expiration
      state.parts[action.payload.id].expired = (
        action.payload.expired
        || (Date.now() - action.payload.uploadedAt > 3 * 30 * 24 * 60 * 60 * 1000)
      );
      state.initialized = true;
    },
    setQuote: (state, action) => {
      state.quotes[action.payload.id] = action.payload;
      // hydrate optionalFeedback
      state.quotes[action.payload.id].optionalFeedback = action.payload.optionalFeedback || [];
      state.quotes[action.payload.id].feedbackResponses = action.payload.feedbackResponses || {};
    },
    setUserPartIDs: (state, action) => {
      state.userPartIDs = action.payload;
    },
    addListeningPartIDs: (state, action) => {
      const listeningPartIDs = action.payload;
      for (let i = 0; i < listeningPartIDs.length; i += 1) {
        state.listeningPartIDs[listeningPartIDs[i]] = true;
      }
    },
    stopPartsListener: () => initialState,
  },
});

export const {
  getPart, getUserParts, setPart, setQuote, stopPartsListener, setUserPartIDs, addListeningPartIDs,
} = partsSlice.actions;

export const getPartListener = createListenerMiddleware();
getPartListener.startListening({
  actionCreator: getPart,
  effect: async (action, { dispatch, condition, getState }) => {
    const { partID } = action.payload;
    const { parts } = getState();
    if (partID in parts.listeningPartIDs) {
      return;
    }

    const part = await getDoc(doc(getFirestore(), 'parts', partID));
    dispatch(setPart({
      id: partID,
      hasPendingWrites: false,
      ...part.data(),
    }));

    const unsubscribeFuncs = [];
    unsubscribeFuncs.push(onSnapshot(doc(getFirestore(), 'parts', partID), { includeMetadataChanges: true }, (snapshot) => {
      dispatch(setPart({
        id: partID,
        hasPendingWrites: snapshot.metadata.hasPendingWrites,
        ...snapshot.data(),
      }));
    }));
    unsubscribeFuncs.push(onSnapshot(doc(getFirestore(), 'quotes', part.data().quote), { includeMetadataChanges: true }, (snapshot) => {
      dispatch(setQuote({
        id: snapshot.id,
        hasPendingWrites: snapshot.metadata.hasPendingWrites,
        ...snapshot.data(),
      }));
    }));
    dispatch(addListeningPartIDs([partID]));
    if (await condition((_, state) => state.parts.status === 'NOT_LISTENING')) {
      unsubscribeFuncs.forEach((unsubscribe) => {
        unsubscribe();
      });
    }
  },
});
export const getUserPartsListener = createListenerMiddleware();
getUserPartsListener.startListening({
  actionCreator: getUserParts,
  effect: async (action, { dispatch, condition }) => {
    const { userID } = action.payload;
    const userPartsQuery = query(collection(getFirestore(), 'parts'), where('uid', '==', userID));
    const parts = await getDocs(userPartsQuery);

    const partIDs = [];
    const unsubscribeFuncs = [];
    parts.docs.forEach((part) => {
      partIDs.push(part.id);
      unsubscribeFuncs.push(onSnapshot(doc(getFirestore(), 'parts', part.id), { includeMetadataChanges: true }, (snapshot) => {
        dispatch(setPart({
          id: part.id,
          hasPendingWrites: snapshot.metadata.hasPendingWrites,
          ...snapshot.data(),
        }));
      }));
      unsubscribeFuncs.push(onSnapshot(doc(getFirestore(), 'quotes', part.data().quote), { includeMetadataChanges: true }, (snapshot) => {
        dispatch(setQuote({
          id: snapshot.id,
          hasPendingWrites: snapshot.metadata.hasPendingWrites,
          ...snapshot.data(),
        }));
      }));
    });
    dispatch(setUserPartIDs(partIDs));
    dispatch(addListeningPartIDs(partIDs));
    if (await condition((_, state) => state.parts.status === 'NOT_LISTENING')) {
      unsubscribeFuncs.forEach((unsubscribe) => {
        unsubscribe();
      });
    }
  },
});

export const selectPart = (partID) => (state) => {
  if (!state.parts.parts[partID]) {
    return { id: partID };
  }
  const quote = state.parts.quotes[state.parts.parts[partID].quote];
  if (quote) {
    return { ...state.parts.parts[partID], quote };
  }
  return { ...state.parts.parts[partID], quote: { id: state.parts.parts[partID].quote } };
};
export const selectParts = ((state) => (
  state.parts.userPartIDs.map((partID) => selectPart(partID)(state)))
);
export const selectPartsInitialized = (state) => state.parts.initialized;

export default partsSlice.reducer;
