import URLSearchParams from 'core-js/stable/url-search-params';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/firebase';

const OrderConfirmation = () => {
  const sessionId = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('session_id');
  }, []);

  const user = useSelector(selectUser);

  useEffect(() => {
    (async () => {
      if (sessionId && user?.email) {
        await httpsCallable(getFunctions(getApp()), 'clearCart')({ sessionId });
      }
    })();
  }, [sessionId, user]);
};

export default OrderConfirmation;
