const getShipDateByDays = (days) => {
  let daysToAdd = days;

  const current = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
  if (current.getHours() >= 17) {
    daysToAdd += 1;
  }

  let daysAdded = 0;
  while (daysAdded < daysToAdd) {
    current.setDate(current.getDate() + 1);
    if (current.getDay() > 0 && current.getDay() < 6
          && !(current.getDate() === 7 && current.getMonth() === 1) // hack for move
          && !(current.getDate() === 8 && current.getMonth() === 1)) {
      daysAdded += 1;
    }
  }
  const weekday = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(current);
  const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(current);
  const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(current);
  return (`${weekday} (${month}/${day})`);
};

const getShipDays = (leadtime) => ({
  'leadtime-standard': 5,
  'leadtime-expedited': 3,
  'leadtime-rush': 2,
}[leadtime]);

const getShipDate = (leadtime) => {
  const daysToAdd = getShipDays(leadtime);
  return getShipDateByDays(daysToAdd);
};

const getFastestLeadtime = (leadtimes) => {
  const STANDARD_LEADTIME = 10;

  let shortestLeadtimeDays = Infinity;
  let firstLeadtimeText = '';
  leadtimes.forEach((leadtime) => {
    const { leadtimeDays, leadtimeText } = leadtime;
    if (leadtimeDays && leadtimeDays < shortestLeadtimeDays) {
      shortestLeadtimeDays = leadtimeDays;
    }
    if (leadtimeText && !firstLeadtimeText) {
      firstLeadtimeText = leadtimeText;
    }
  });

  // prfer leadtime days
  if (shortestLeadtimeDays !== Infinity) {
    return { leadtimeDays: shortestLeadtimeDays };
  // otherwise, choose the first text leadtime
  } if (firstLeadtimeText) {
    return { leadtimeText: firstLeadtimeText };
  }
  // if nothing is found, return standard leadtime
  return { leadtimeDays: STANDARD_LEADTIME };
};

module.exports = {
  getShipDate, getShipDays, getShipDateByDays, getFastestLeadtime,
};
