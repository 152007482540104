import { getFastestLeadtime } from '@parallel-fluidics/shipping';
import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import {
  collection,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';

import { getShipDateByDays } from '../../helpers/getShipDate';

const initialState = {
  parts: [],
  partsHavePendingWrites: false,
  accessoryLeadtime: getFastestLeadtime([]),
  accessories: [],
  accessoriesHavePendingWrites: false,
  status: 'NOT_LISTENING',
  initialized: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    getCart: (state) => {
      state.status = 'LISTENING';
    },
    setParts: (state, action) => {
      const { parts, partsHavePendingWrites, accessoryLeadtime } = action.payload;
      state.parts = parts;
      state.partsHavePendingWrites = partsHavePendingWrites;
      state.initialized = true;
      state.accessoryLeadtime = accessoryLeadtime;
    },
    setAccessories: (state, action) => {
      const { accessories, accessoriesHavePendingWrites } = action.payload;
      state.accessories = accessories;
      state.accessoriesHavePendingWrites = accessoriesHavePendingWrites;
      state.initialized = true;
    },
    stopPartsListener: () => initialState,
  },
});

export const { getCart, setParts, setAccessories } = cartSlice.actions;

export const getCartListener = createListenerMiddleware();
getCartListener.startListening({
  actionCreator: getCart,
  effect: async (action, { dispatch, condition }) => {
    const { userID } = action.payload;

    const unsubscribeItems = onSnapshot(collection(getFirestore(), 'carts', userID, 'items'), { includeMetadataChanges: true }, (snapshot) => {
      const parts = [];
      snapshot.forEach((doc) => {
        const {
          delivery,
          vias,
          leadtimeText,
          leadtimeDays,
        } = doc.data();

        // calculate connector info
        let numThrougholes = 0;
        let numConnectors = 0;
        if (vias && vias.length > 0) {
          vias.forEach((via) => {
            if (!['placeholder-item', 'none'].includes(via.connector)) {
              numConnectors += 1;
            } else {
              numThrougholes += 1;
            }
          });
        }

        // calculate shipping text
        let shippingText = '';
        if (leadtimeText) {
          shippingText = `${{
            'leadtime-standard': 'Standard',
            'leadtime-expedited': 'Expedited',
            'leadtime-rush': 'Rush',
          }[delivery]}: ${leadtimeText}`;
        } else {
          shippingText = `${{
            'leadtime-standard': 'Standard',
            'leadtime-expedited': 'Expedited',
            'leadtime-rush': 'Rush',
          }[delivery]}: Ships ${getShipDateByDays(leadtimeDays)}`;
        }

        parts.push({
          id: doc.id,
          numThrougholes,
          numConnectors,
          shippingText,
          ...doc.data(),
        });
      });
      parts.sort((a, b) => a.timeAdded - b.timeAdded);
      const fastestLeadtime = getFastestLeadtime(parts);
      dispatch(setParts({
        partsHavePendingWrites: snapshot.metadata.hasPendingWrites,
        parts,
        accessoryLeadtime: fastestLeadtime,
      }));
    });

    const unsubscribeAccessories = onSnapshot(collection(getFirestore(), 'carts', userID, 'accessories'), { includeMetadataChanges: true }, (snapshot) => {
      const accessories = [];
      snapshot.forEach((doc) => {
        const {
          name, url, quantity, unitPrice,
        } = doc.data();
        const { id } = doc;
        accessories.push({
          id, name, url, quantity, unitPrice,
        });
      });
      dispatch(setAccessories({
        partsHavePendingWrites: snapshot.metadata.hasPendingWrites,
        accessories,
      }));
    });

    if (await condition((_, state) => state.parts.status === 'NOT_LISTENING')) {
      unsubscribeItems();
      unsubscribeAccessories();
    }
  },
});

export const selectCartParts = (state) => state.cart.parts;
export const selectCartAccessories = (state) => state.cart.accessories;
export const selectAccessoryLeadtime = (state) => {
  const { leadtimeText, leadtimeDays } = state.cart.accessoryLeadtime;
  let shippingText = '';
  if (leadtimeText) {
    shippingText = leadtimeText;
  } else {
    shippingText = `Ships ${getShipDateByDays(leadtimeDays)}`;
  }
  return shippingText;
};
export const selectCartSize = (state) => state.cart.parts.length + state.cart.accessories.length;
export const selectCartHasPendingChanges = (state) => (
  state.cart.partsHavePendingWrites || state.cart.accessoriesHavePendingWrites);
export const selectCartInitialized = (state) => state.cart.initialized;

export default cartSlice.reducer;
