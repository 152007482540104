import { configureStore } from '@reduxjs/toolkit';

import cartReducer, { getCartListener } from '../features/cart';
import firebaseReducer from '../features/firebase';
import partsReducer, { getPartListener, getUserPartsListener } from '../features/parts';

const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    parts: partsReducer,
    cart: cartReducer,
  },
  middleware: ((getDefaultMiddleware) => getDefaultMiddleware()
    .prepend(getUserPartsListener.middleware)
    .prepend(getPartListener.middleware)
    .prepend(getCartListener.middleware)),
});

export default store;
