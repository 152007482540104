const getShipDateByDays = (days) => {
  let daysToAdd = days;

  const current = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
  if (current.getHours() >= 17) {
    daysToAdd += 1;
  }

  let daysAdded = 0;
  while (daysAdded < daysToAdd) {
    current.setDate(current.getDate() + 1);
    if (current.getDay() > 0 && current.getDay() < 6
    && !(current.getDate() === 15 && current.getMonth() === 0) // Martin Luther King Jr. Day
    && !(current.getDate() === 15 && current.getMonth() === 3) // Patriot's Day
    && !(current.getDate() === 27 && current.getMonth() === 4) // Memorial Day
    && !(current.getDate() === 19 && current.getMonth() === 5) // Juneteenth
    && !(current.getDate() === 4 && current.getMonth() === 6) // Independence Day
    && !(current.getDate() === 2 && current.getMonth() === 8) // Labor Day
    && !(current.getDate() === 14 && current.getMonth() === 9) // Indigenous Peoples' Day
    && !(current.getDate() === 11 && current.getMonth() === 10) // Veterans Day
    && !(current.getDate() === 28 && current.getMonth() === 10) // Thanksgiving
    && !(current.getDate() === 29 && current.getMonth() === 10) // day after Thanksgiving
    && !(current.getDate() > 23 && current.getMonth() === 11)) { // Christmas
      daysAdded += 1;
    }
  }
  const weekday = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(current);
  const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(current);
  const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(current);
  return (`${weekday} (${month}/${day})`);
};

const getShipDate = (leadtime) => {
  const daysToAdd = {
    'leadtime-standard': 5,
    'leadtime-expedited': 3,
    'leadtime-rush': 2,
  }[leadtime];

  return getShipDateByDays(daysToAdd);
};

export default getShipDate;
export { getShipDateByDays };
