import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import analytics from '../../app/analytics';
import { getCart } from '../cart';
import { getUserParts, stopPartsListener } from '../parts';

const config = {
  apiKey: 'AIzaSyBt2srWv32zo6RnBikmU8xw4HlQ1YWVwBk',
  authDomain: 'parallel-fluidics.firebaseapp.com',
  databaseURL: 'https://parallel-fluidics.firebaseio.com',
  projectId: 'parallel-fluidics',
  storageBucket: 'parallel-fluidics.appspot.com',
  messagingSenderId: '964430373671',
  appId: '1:964430373671:web:bf07eb7eb3919979e3ff02',
  measurementId: 'G-KX25FNPG3B',
};

const initialState = {
  user: null,
  status: 'uninitialized',
};

export const initialize = createAsyncThunk(
  'firebase/initialize',
  async (_, { dispatch }) => {
    initializeApp(config);
    window.signOut = () => {
      analytics.signOut();
      signOut(getAuth());
    };
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (user.email) {
          if (user.uid) {
            Sentry.setUser({ id: user.uid });
          }
        }
        dispatch({
          type: 'firebase/setUser',
          payload: {
            user: {
              email: user.email,
              id: user.uid,
            },
          },
        });
        dispatch(getUserParts({ userID: user.uid }));
        dispatch(getCart({ userID: user.uid }));
      } else {
        Sentry.setUser(null);
        dispatch({ type: 'firebase/setUser', payload: { user: null } });
        dispatch(stopPartsListener);
      }
    });
  },
  {
    condition: (_, { getState }) => {
      const { firebase } = getState();
      return firebase.status === 'uninitialized';
    },
  },
);

export const firebaseSlice = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;

      // https://stackoverflow.com/a/54739744
      // wait until user is set for the first time to determine if
      // Firebase auth is initialized (this will trigger even for a
      // null user)
      state.status = 'initialized';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initialize.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const selectUser = (state) => state.firebase.user;
export const selectStatus = (state) => state.firebase.status;

export default firebaseSlice.reducer;
